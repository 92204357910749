import requests from "./request";
import qs from "qs";
//登录接口
export const loginapi = (paramser) => {
    paramser = qs.stringify(paramser)
    return requests({
        url: "/login/ass-login",
        method: "post",
        data: paramser
    })
}

// 老师评估—列表
export const getteacherlist = (is_ass) =>
    requests({
        url: "/jm-reviewfee/pad-list",
        method: "get",
        params: {
            is_ass
        }
    })
//学生查询
export const getstudent = ({ is_ass = "", name, mobile, stime, etime }) =>
    requests({
        url: "/jm-reviewfee/pad-list?" + "is_ass=" + is_ass + "&" + "name=" + name + "&" +"mobile=" + mobile + "&" + "stime=" + stime + "&" + "etime=" + etime,
        method: "get"
    })

//评估题--选择维度列表
export const getweidu = (uid, review_id) => {
    return requests({
        // url: "/must-topic/main-list?uid=" + uid + "&review_id=" + review_id,
        url: "/must-topic/main-list",
        method: "get",
        params:{
            uid:uid,
            review_id:review_id
        }
    })

}


//评估题—练习题详情
// export const getdetails = (id) => {
//     return requests({
//         url: "/must-topicpract/get",
//         method: "get",
//         params: {
//             id: id
//         }
//     })
// }


// 评估题—详情-已弃用
export const gettablelist = (uid, reviewid, mainid, topic_sort) => {
    return requests({
        url: "/must-topic/topic-info",
        method: "get",
        params: {
            uid: uid,
            review_id: reviewid,
            main_id: mainid,
            topic_sort: topic_sort
        }
    })
}

// 下一个评估题信息
export const nextTopic = (params) => {
    return requests({
        url: "/must-topic/next-topic",
        method: "get",
        params: params
    })
}

// 下一个评估题内容
export const nextContent = (params) => {
    return requests({
        url: "/must-topic/next-content",
        method: "get",
        params: params
    })
}

// 答题-加表格附加项目标签—添加
export const programadd = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-attachitem/add', data)
}

// 答题-右侧评估题分数列表
export const rightscore = (uid, reviewid, mainid) => {
    return requests({
        url: "/must-trecord/trecord?" + "uid=" + uid + "&" + "review_id=" + reviewid + "&" + "main_id=" + mainid,
        method: "get"
    })
}
// 答题-加表格选择项目添加
export const chooseproject = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-toprecgrid/add', data)
}
// 答题-加表格题项目列表
export const getproject = (uid, reviewid, topic_id) => {
    return requests({
        url: "/must-toprecgrid/list?" + "uid=" + uid + "&" + "review_id=" + reviewid + "&" + "topic_id=" + topic_id,
        method: "get"
    })
}
// 答题-加表格题项目删除
export const delchoose = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-toprecgrid/del', data)
}
// 评估题--答题记录添加
export const recordadd = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-topicrecord/add', data)
}
// 评估题--
// export const Evaluationtopic= (data) => {
//     data = qs.stringify(data)
//     return requests.post('/must-topicrecord/add', data)
// }
// 评估题--练习题详情

export const getexercises = (id) => {
    return requests({
        url: "/must-topicpract/get?" + "id=" + id,
        method: "get"
    })
}

// 获取视频播放凭证
export const getPlayAuth = (data) => {
    data = qs.stringify(data)
    return requests.post("/must-topicpract/video-key", data)
}

// 评估题--完成评估提交接口
export const finishAsses = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-trecord/finish-asses', data)
}
// 答题记录—试题信息
export const topicRecord = (uid, topic_sort, review_id, main_id, type) => {
    return requests({
        url: "/must-topicrecord/get",
        method: "get",
        params: {
            uid: uid,
            topic_sort: topic_sort,
            review_id: review_id,
            main_id: main_id,
            type: type
        }
    })
}
// 评估题--完成评估提交接口
export const finish = (data) => {
    data = qs.stringify(data)
    return requests.post('/must-trecord/finish', data)
}

// 首页-列表
export const home = (data) => {
    return requests.get("/pad/pad/home",{ params: { ...data } })
}






























