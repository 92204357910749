let key = "uid";
export const setuid = (val) => {
    return sessionStorage.setItem(key, val)
}
export const getuid = () => {
    return sessionStorage.getItem(key)
}
export const removeuid = () => {
    return sessionStorage.removeItem(key)
}

let key1 = "review_id";
export const setreviewid = (val) => {
    return sessionStorage.setItem(key1, val)
}
export const getreviewid = () => {
    return sessionStorage.getItem(key1)
}
export const removereviewid = () => {
    return sessionStorage.removeItem(key1)
}

let key2 = "main_id";
export const settopicid = (val) => {
    return sessionStorage.setItem(key2, val)
}
export const gettopicid = () => {
    return sessionStorage.getItem(key2)
}
export const removetopicid = () => {
    return sessionStorage.removeItem(key2)
}


let key3 = "ltemname";
export const setltemname= (val) => {
    return sessionStorage.setItem(key3, val)
}
export const getltemname = () => {
    return sessionStorage.getItem(key3)
}
export const removeltemname = () => {
    return sessionStorage.removeItem(key3)
}

let key4 = "next_index";
export const setnext= (val) => {
    return sessionStorage.setItem(key4, val)
}
export const getnext = () => {
    return sessionStorage.getItem(key4)
}
export const removenext = () => {
    return sessionStorage.removeItem(key4)
}

let key5 = "topic_num";
export const settopic_num= (val) => {
    return sessionStorage.setItem(key5, val)
}
export const gettopic_num = () => {
    return sessionStorage.getItem(key5)
}
export const removetopic_num = () => {
    return sessionStorage.removeItem(key5)
}

let key6 = "lesson_id";
export const setlesson_id= (val) => {
    return sessionStorage.setItem(key6, val)
}
export const getlesson_id = () => {
    return sessionStorage.getItem(key6)
}
export const removelesson_id = () => {
    return sessionStorage.removeItem(key6)
}
