<template>
  <div class="index_page">
    <van-nav-bar :left-text="username" right-text="退出">
      <template #left>
        <div class="image">
          <img src="@/assets/img/icon_tea.png" alt />
        </div>
        <p>{{ username }}</p>
      </template>
      <template #right>
        <div class="image">
          <img src="@/assets/img/ICON2x.png" alt />
        </div>
        <p class="exit" @click="exit">退出</p>
      </template>
    </van-nav-bar>
    <div class="content">
      <div class="my_box">
        <div>
          <div class="title">
            <p>我的学员</p>
            <span @click="goStuList">查看›</span>
          </div>
          <div class="info">
            <div class="image">
              <img src="@/assets/img/my1.png" alt />
            </div>
            <p>
              <span>{{ info.stu_num }}</span>个学员
            </p>
          </div>
        </div>
        <div v-if="assessShow">
          <div class="title">
            <p>我的评估</p>
            <span @click="goAssessList">查看›</span>
          </div>
          <div class="info">
            <div class="image">
              <img src="@/assets/img/my2.png" alt />
            </div>
            <p>
              <span>{{ info.ass_num }}</span>个学员
            </p>
          </div>
        </div>
      </div>
      <div class="today course">
        <div class="title">今日课程</div>
        <div class="list">
          <!-- {"id":9241,"uid":2097,"c_date":1685030400,"poin_time":"15:40-16:20","stime":1685086800,"etime":1685089200,"group_id":97,"subject":1,"lesson_id":303,"u_name":"是劳动","u_face":"","c_status":0,"g_name":"我是小组名称","g_extent":0,"les_name":"个训."} -->
          <div v-if="info.day_course && info.day_course.length > 0">
            <van-swipe ref="swiper1" :loop="false" :width="160" :show-indicators="false">
              <van-swipe-item v-for="item in info.day_course" :key="item.id">
                <div class="item" :class="{ finish: item.c_status == 2 }" @click="goClass(item)">
                  <div class="image" v-if="item.subject == 1">
                    <img :src="item.u_face" alt />
                  </div>
                  <div class="level" v-else>
                    <span>{{ extentTxt[item.g_extent] }}</span>
                  </div>
                  <div class="stu_name">{{ item.subject == 1 ? item.u_name : item.g_name }}</div>
                  <div class="course_name">({{ item.les_name }})</div>
                  <div class="time">{{ item.poin_time }}</div>
                  <div class="tip">{{ courseStatusTxt[item.c_status] }}</div>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="van_btn" v-if="info.day_course.length > 4">
              <div class="prev" @click="prev(1)">
                <van-icon name="arrow-left" />
              </div>
              <div class="next" @click="next(1)">
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <van-empty description="今日暂无课程" image-size="120px" />
          </div>
        </div>
      </div>

      <div class="today assess" v-if="assessShow">
        <div class="title">今日评估</div>
        <div class="list">
          <div v-if="info.fee_list && info.fee_list.length > 0">
            <van-swipe ref="swiper2" :loop="false" :width="160" :show-indicators="false">
              <van-swipe-item v-for="item in info.fee_list" :key="item.id">
                <div class="item" :class="{ finish: item.k_status == 2 }" @click="goAssess(item)">
                  <div class="image">
                    <img :src="item.u_face" alt />
                  </div>
                  <div class="stu_name">{{ item.u_name }}</div>
                  <div class="time">{{ item.k_time }}</div>
                  <div class="tip">{{ assessStatusTxt[item.k_status] }}</div>
                </div>
              </van-swipe-item>
            </van-swipe>
            <div class="van_btn" v-if="info.fee_list.length > 4">
              <div class="prev" @click="prev(2)">
                <van-icon name="arrow-left" />
              </div>
              <div class="next" @click="next(2)">
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
          <div v-else class="empty">
            <van-empty description="今日暂无评估" image-size="120px" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify, Toast } from "vant";
import { getLabelId } from "../api/token";
import { home } from "@/api/index";
import {
  getname,
  removetoken,
  removeed,
  removetype,
  removename,
  removeLabelId,
} from "/src/api/token.js";
import { setuid, setreviewid } from "/src/api/userdata.js";
export default {
  data() {
    return {
      username: "",
      labelId: [],
      assessShow: false,
      info: {},
      extentTxt: [
        "",
        "语萌一",
        "语萌二",
        "语萌三",
        "语进一",
        "语进二",
        "语进三",
        "语达一",
        "语达二",
        "语达三",
        "幼儿园融合班",
        "幼儿园实验班",
        "小学先行班",
      ],
      courseStatusTxt: ["未上课", "上课中", "已完成", "未备课"],
      assessStatusTxt: ["", "未评估", "已完成", "已完成", "评估中"],
    };
  },
  created() {
    this.getTeaInfo();
    this.getList();
  },
  methods: {
    getTeaInfo() {
      const teachername = getname();
      const labelId = getLabelId();
      this.username = teachername;
      this.labelId = labelId.split(",");
      this.assessShow = this.labelId.some((e) => e == 1);
    },
    async getList() {
      let res = await home();
      if (res.code === 9990) {
        Notify(res.msg);
        this.$router.push({
          name: "login",
        });
      }
      // console.log(res);
      if (res.code * 1 !== 1) {
        return Notify(res.message);
      }
      this.info = res.data;
    },
    exit() {
      this.$dialog
        .confirm({
          message: "是否确认退出",
          confirmButtonColor: "fff",
          messageAlign: "left",
          getContainer: ".index_page",
        })
        .then(() => {
          removetype();
          removeed();
          removetoken();
          removename();
          removeLabelId();
          this.$router.push({
            name: "login",
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    prev(i) {
      this.$refs[`swiper${i}`].prev();
    },
    next(i) {
      this.$refs[`swiper${i}`].next();
    },
    goClass(e) {
      console.log(e);
      if (e.c_status == 2) {
        return;
      }
      // if (e.c_status == 1) {
      // 	return console.log('上课中：进入课程记录页面');
      // }
      if (e.c_status == 3) {
        this.$dialog
          .confirm({
            message: "请到排课系统-课程管理-日计划中备课",
            confirmButtonColor: "fff",
            messageAlign: "left",
            getContainer: ".index_page",
            showCancelButton: false,
          })
          .then(() => {
            console.log("确定");
          })
          .catch(() => {
            console.log("取消");
          });
        return;
      }
      if (e.u_status == 1) {
        return Toast("上一节课未完成无法上课");
      }
      this.$router.push({
        name: "startClass",
        query: {
          id: e.id,
          subject: e.subject,
          u_name: e.u_name,
          uid: e.uid,
          g_name: e.g_name,
          les_name: e.les_name,
          poin_time: e.poin_time,
          room_name: e.room_name,
          ass_name: e.ass_name,
          stime: e.stime,
          etime: e.etime,
          review_id: e.review_id,
          tearecord_id: e.tearecord_id
        },
      });
    },
    goAssess(e) {
      if ([e.k_status] == 2) {
        return;
      }
      setuid(e.uid);
      setreviewid(e.id);
      if (e.e_type == 5) {
        this.$router.push({
          name: "oralSnag",
          query: {
            uid: e.uid,
            review_id: e.id,
            ass_time: e.k_date,
            time: e.k_time,
          },
        });
      } else if (e.e_type == 3) {
        this.$router.push({
          name: "oralAssessment",
          query: {
            uid: e.uid,
            review_id: e.id,
            ass_time: e.k_date,
            time: e.k_time,
            ass_num: e.ass_num,
            eva_type: e.e_type,
          },
        });
      } else {
        this.$router.push({
          name: "startpingu",
          query: {
            ass_num: e.ass_num,
            eva_type: e.e_type
          }
        });
      }
    },
    goAssessList() {
      this.$router.push({
        name: "AssessmentRecords",
      });
    },
    goStuList() {
      this.$router.push({
        name: "mystu",
        params: {
          list: this.info.stu_list,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.image {
  font-size: 0;
  line-height: 0;
}
img {
  width: 100%;
}
.van-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /deep/ .van-nav-bar__content {
    height: 0.58rem;
  }
  /deep/ .van-nav-bar__left,
  .van-nav-bar__right {
    font-size: 0.16rem;
    color: #333333;
    .van-nav-bar__text {
      color: #333;
    }
  }
  /deep/ .van-nav-bar__left {
    font-size: 0.18rem;
    font-weight: bold;
    .image {
      width: 0.3rem;
      margin-right: 0.12rem;
    }
  }
  /deep/ .van-nav-bar__right {
    .image {
      width: 0.16rem;
      margin-right: 0.09rem;
    }
  }
  &::after {
    border: none;
  }
}
.index_page {
  background: #fbf7f7;
  min-height: 100vh;
  padding-top: 0.58rem;
  .content {
    padding: 0.17rem 0.2rem;
    .my_box {
      display: flex;
      justify-content: flex-start;
      > div {
        width: 2.28rem;
        height: 1.2rem;
        background: linear-gradient(314deg, #ffec70 0%, #ff9e20 100%);
        border-radius: 0.1rem;
        padding: 0.07rem 0.1rem;
        margin-left: 0.13rem;
        box-shadow: 0 0.05rem 0.08rem 0.01rem RGBA(252, 220, 181, 0.5);
        color: #580100;
        .title {
          border-bottom: 0.01rem solid rgba(255, 255, 255, 0.3);
          padding-bottom: 0.05rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-size: 0.16rem;
          }
          span {
            font-size: 0.12rem;
          }
        }
        .info {
          margin-top: 0.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          .image {
            width: 0.35rem;
            margin-right: 0.1rem;
          }
          p {
            font-size: 0.13rem;
            span {
              font-size: 0.26rem;
              font-weight: bold;
            }
          }
        }
        &:first-child {
          background: linear-gradient(314deg, #ffa5a5 0%, #ff7373 100%);
          margin-left: 0;
          box-shadow: 0 0.05rem 0.08rem 0.01rem RGBA(248, 200, 200, 0.5);
          color: #fff;
        }
      }
    }
    .today {
      background: #fff;
      margin-top: 0.16rem;
      padding: 0.1rem 0 0.2rem;
      border-radius: 0.1rem;
      .title {
        font-size: 0.2rem;
        color: #666666;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 0.15rem;
        &::before {
          content: "";
          display: block;
          width: 0.05rem;
          height: 0.26rem;
          background: #ff5f4c;
          border-radius: 0px 0.1rem 0.1rem 0px;
          margin-right: 0.15rem;
        }
      }
      .list {
        padding: 0 0.2rem;
        position: relative;
        .van-swipe-item {
          padding: 0 0.07rem;
          .item {
            width: 1.45rem;
            height: 1.8rem;
            background: #fffcfb;
            border-radius: 0.1rem;
            border: 0.01rem solid #ffd9d9;
            text-align: center;
            padding: 0.25rem 0 0.15rem;
            font-size: 0.16rem;
            color: #666666;
            position: relative;
            overflow: hidden;
            .image,
            .level {
              width: 0.55rem;
              height: 0.55rem;
              border-radius: 50%;
              overflow: hidden;
              margin: 0 auto 0.05rem;
              img {
                height: 100%;
              }
            }
            .level {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 0.01rem solid #ffd9d5;
              background: #fff;
              span {
                font-size: 0.12rem;
                color: #ff5f4c;
              }
            }
            .stu_name {
              margin-bottom: 0.05rem;
            }
            .time {
              margin-top: 0.1rem;
            }
            .tip {
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              line-height: 0.22rem;
              background: #f14947;
              border-radius: 0px 0.1rem;
              font-size: 0.13rem;
              color: #fff;
              padding: 0 0.1rem;
            }
            &.finish {
              border: none;
              &::after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                position: absolute;
                top: 0;
                left: 0;
              }
              .tip {
                background: #d9d9d9;
              }
            }
          }
        }
        .van_btn div {
          width: 0.34rem;
          height: 0.34rem;
          background: #ffffff;
          box-shadow: 0px 0px 0.2rem 0.01rem rgba(159, 0, 0, 0.18);
          border-radius: 50%;
          text-align: center;
          line-height: 0.34rem;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          .van-icon {
            font-size: 0.2rem;
          }
          &.prev {
            left: 0.03rem;
          }
          &.next {
            right: 0.03rem;
          }
        }
      }
    }
    .assess {
      .list .van-swipe-item .item {
        height: 1.56rem;
      }
    }
  }
}
.van-empty {
  padding: 0;
}
/deep/ .van-empty__description {
  font-size: 0.16rem;
}
/deep/ .van-overlay {
  background: rgba(0, 0, 0, 0.3);
}
/deep/ .van-dialog {
  width: 3.3rem;
  padding-bottom: 0.2rem;
  .van-dialog__content--isolated {
    min-height: 0.8rem;
  }
  .van-dialog__header {
    text-align: left;
    padding: 0.2rem 0.24rem 0;
    color: #666;
  }
  .van-dialog__header::before {
    content: "!";
    display: inline-block;
    width: 0.18rem;
    height: 0.18rem;
    border-radius: 50%;
    text-align: center;
    font-size: 0.18rem;
    vertical-align: text-bottom;
    margin-right: 0.06rem;
    line-height: 0.2rem;
    padding: 0 0 0.01rem 0.01rem;
    background: #ffaa20;
    color: #fff;
  }
  .van-dialog__message--has-title {
    padding-top: 0.1rem;
    padding-bottom: 0.27rem;
    font-size: 0.14rem;
    line-height: 0.3rem;
  }
  .van-dialog__message {
    color: #666;
    font-size: 0.15rem;
    padding: 0.2rem;
    text-align: center;
  }
  .van-dialog__footer {
    justify-content: center;
    overflow: initial;
    .van-button {
      width: 0.94rem;
      height: 0.34rem;
      flex: none;
      border-radius: 0.06rem;
      margin: 0 0.13rem;
      font-size: 0.16rem;
    }
    .van-dialog__cancel {
      border: 1px solid #b2b2b2;
      color: #707070;
    }
    .van-dialog__confirm {
      background: #fa4b52;
      color: #fff;
    }
  }
  [class*="van-hairline"]::after {
    border: none;
  }
}
</style>